export interface TsAppVersion {
    version: string;
    name: string;
    description?: string;
    versionLong?: string;
    versionDate: string;
    gitCommitHash?: string;
    gitCommitDate?: string;
    gitTag?: string;
};
export const versions: TsAppVersion = {
    version: '2.2.14',
    name: 'fpar',
    versionDate: '2025-02-13T22:03:22.845Z',
};
export default versions;
